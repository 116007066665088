import React from "react";
import useGameStore from "../lib/store/GameStore";
import usePersistentStore from "../lib/store/PersistentStore";
import { hasHeadshot } from "../lib/utils/constants";
import { getPlayerById, getTeamByAbbr } from "../lib/utils/helpers";

export function ShareDiv({}) {
  const [showShareDiv, shareGroup, orderByTeamId] = useGameStore((state) => [
    state.showShareDiv,
    state.shareGroup,
    state.orderByTeamId,
  ]);

  return (
    showShareDiv &&
    orderByTeamId && (
      <div style={{ marginTop: "1000px" }}>
        <div id="shareDiv" className="max-w-sm">
          <div className="bg-gradient-to-b from-sky-950 to-sky-600 px-2 pb-3 pt-4 sm:max-w-sm">
            <div className="mx-auto mb-4 max-w-screen-sm text-center">
              <div className="mb-1 inline-flex items-center font-anton text-2xl text-white antialiased dark:text-white">
                <span className="text-lime-400">DRAFT</span>PHANTOM.com
              </div>
              <h3 className="text-md font-bold tracking-tight text-white antialiased dark:text-gray-200">
                Predict the 2023 AFL Draft
              </h3>
            </div>
            <div className="mx-auto grid max-w-md grid-cols-2 gap-x-2 gap-y-2 rounded-sm">
              <div
                id="leaderboard-inner"
                className="mx-auto w-full max-w-md space-y-1.5 rounded-sm bg-slate-200 p-1.5"
              >
                <ShareRows start={1 + (shareGroup - 1) * 16} num={8} />
              </div>
              <div
                id="leaderboard-inner"
                className="mx-auto w-full max-w-md space-y-1.5 rounded-sm bg-slate-200 p-1.5"
              >
                <ShareRows start={9 + (shareGroup - 1) * 16} num={8} />
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  );
}

function ShareRow({ overallPick }) {
  const [players, orderByTeamId] = useGameStore((state) => [
    state.players,
    state.orderByTeamId,
  ]);

  const [picks, currentPick] = usePersistentStore((state) => [
    state.picks,
    state.currentPick,
  ]);
  const onTheClock = false; //pick.overallPick === currentPick;

  const selectedPlayerId = picks[overallPick];
  const selectedPlayer = getPlayerById(selectedPlayerId);

  const teamAbbr = orderByTeamId[overallPick - 1];
  const team = getTeamByAbbr(teamAbbr);

  return (
    <>
      <div
        className={`flex w-full items-center transition-transform ${
          onTheClock ? "hover:scale-[1.01] active:scale-[.98]" : ""
        }`}
      >
        <div className={`w-full ${onTheClock ? "bg-lime-500" : "bg-white"}`}>
          <div className="flex items-center py-0.5">
            <div
              id="rank"
              className={`flex h-8 w-5 min-w-[1.75rem] flex-none items-center justify-center font-oswald text-lg font-bold tracking-tight ${
                onTheClock ? "text-white" : "text-sky-700"
              } antialiased`}
            >
              {overallPick}
            </div>
            <div id="name" className="flex flex-none items-center">
              <img
                className="mr-1.5 h-6 w-6"
                src={`/build/images/sport/teams/logos/512/${team.id}.png`}
                alt={`${team.name} colours`}
              />
              {selectedPlayer && (
                <div className="flex flex-col">
                  <div className="text-ellipsis text-left text-xs font-medium uppercase leading-none text-sky-950 antialiased">
                    {selectedPlayer.firstName}
                  </div>
                  <div className="text-ellipsis text-left font-anton text-md uppercase leading-none text-sky-950 antialiased">
                    {selectedPlayer.lastName}
                  </div>
                </div>
              )}
            </div>
            <div className="grow"></div>
            <div className="relative -ml-14 flex h-8 w-14 flex-none items-center justify-center">
              <div className="absolute h-10 w-14">
                <div className="absolute -top-0.5 flex h-full w-full items-start justify-center overflow-hidden">
                  {selectedPlayer &&
                    (hasHeadshot.includes(selectedPlayer.id) ? (
                      <img
                        src={`/build/images/phantom/juniorplayers/${selectedPlayer.id}.png`}
                        alt={`${selectedPlayer.name} headshot`}
                        className="absolute -right-2.5 -top-2"
                      />
                    ) : (
                      <img
                        src={`/build/images/phantom/juniorplayers/silhouette.png`}
                        alt={`${selectedPlayer.name} headshot`}
                        className="absolute -right-2.5 -top-2"
                      />
                    ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export function ShareRows({ order, start, num }) {
  const picks = [];
  for (let pickNum = start; pickNum < start + num; pickNum++) {
    picks.push(<ShareRow key={pickNum} overallPick={pickNum} />);
  }

  return picks;
}
